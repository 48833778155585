import type { LcIconName } from '@lottocom/frontend-components'

export const REGISTER_FORM_ACCOUNT = {
  Email: 'email',
  Password: 'password',
  PasswordConfirm: 'passwordConfirm',
} as const

export const REGISTER_FORM_ADDRESS = {
  Additionalinfo: 'additionalInfo',
  Autocomplete: 'google-address-autocomplete',
  City: 'city',
  Country: 'country',
  State: 'state',
  StateLong: 'stateLong',
  StreetName: 'streetName',
  StreetNumber: 'streetNumber',
  Zipcode: 'zipCode',
} as const

export const REGISTER_FORM_IDS = {
  Account: 'account',
  Address: 'address',
  Name: 'name',
  PersonalData: 'personalData',
} as const

export const REGISTER_FORM_NAME = {
  FamilyName: 'familyName',
  GivenName: 'givenName',
} as const

export const REGISTER_FORM_PERSONAL_DATA = {
  CountryCode: 'countryCode',
  DateOfBirth: 'dateOfBirth',
  PhoneNumber: 'phoneNumber',
} as const

export type RegisterFormId = ObjectValues<typeof REGISTER_FORM_IDS>

export type RegisterStep = {
  icon: LcIconName
  id: RegisterFormId
  progress: { max: number; min: number }
  route: string
}

export const REGISTER_CONFIG: readonly RegisterStep[] = [
  {
    icon: 'profile-person',
    id: REGISTER_FORM_IDS.Name,
    progress: { max: 25, min: 0 },
    route: '/register/name',
  },
  {
    icon: 'phone-call',
    id: REGISTER_FORM_IDS.PersonalData,
    progress: { max: 50, min: 25 },
    route: '/register/personaldata',
  },
  {
    icon: 'location',
    id: REGISTER_FORM_IDS.Address,
    progress: { max: 75, min: 50 },
    route: '/register/address',
  },
  {
    icon: 'lock',
    id: REGISTER_FORM_IDS.Account,
    progress: { max: 100, min: 75 },
    route: '/register/account',
  },
] as const
